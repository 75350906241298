import React from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';
import ModuleRow from './ModuleRow';

export default function InspectionTemplate({idType,informationInspection,suggestionType,stateActionPlan}) {
    console.log("🚀 ~ InspectionTemplate ~ informationInspection:", informationInspection)
    const datosInspeccion = {
        // "id_inspeccion" : informationInspection?.id_inspeccion
    };
    if (+idType === 4 || +idType === 3 || +idType === 1) {
        //datos para dto/epp/art
        datosInspeccion["Area"] = informationInspection?.area
        datosInspeccion["Sub-area"] = informationInspection?.subArea
        datosInspeccion["Fecha de la evaluación"] = informationInspection?.fecha
        datosInspeccion["Hora de la evaluación"] = informationInspection?.hora
        datosInspeccion["PETS"] = informationInspection?.pets
        datosInspeccion["Evaluado"] = informationInspection?.evaluado
        datosInspeccion["Supervisor del evaluado"] = informationInspection?.supervisor
        datosInspeccion["Evaluador"] = informationInspection?.evaluador
        datosInspeccion["Puntaje"] = informationInspection?.puntaje 
    }
    if (+idType === 5 || +idType === 6) {
        //datos para 5s/aa
        datosInspeccion["Area"] = informationInspection?.area
        datosInspeccion["Sub-area"] = informationInspection?.subArea
        datosInspeccion["Fecha Inspección"] = informationInspection?.fecha
        datosInspeccion["Auditor"] = informationInspection?.evaluador || ""
        datosInspeccion["Auditado"] = informationInspection?.evaluado || ""
    }
    if (+idType === 2) {
        //datos para sst
        datosInspeccion["Area"] = informationInspection?.area
        datosInspeccion["Sub-area"] = informationInspection?.subArea
        datosInspeccion["Responsable Area"] = informationInspection?.supervisor || ""
        datosInspeccion["Responsable Inspección"] = informationInspection?.evaluador || ""
        datosInspeccion["Fecha de la Inspección"] = informationInspection?.fecha
        datosInspeccion["Hora de la inspección"] = informationInspection?.hora  
    }
    const datos = Object.entries(datosInspeccion);

    const extraStep = {
        id_pregunta: 'extra-step',
        desPregunta: 'Número de pasos',
        puntaje: "30.00",
        tipo_respuesta: {
            desTipoRespuesta: 'opt',
            opciones: [
                { value: 'Ok', label: 'Ok' },
                { value: 'NO', label: 'N/O' },
                { value: 'NA', label: 'N/A' },
            ],
            desc2: 'answer',
        },
    };
    const groupedSugerencias = suggestionType?.reduce((acc, tipo) => {
        acc[tipo.id_tipoSugerencia] = informationInspection?.sugerencias?.filter(sug => +sug.tipoSugerencia_id === +tipo.id_tipoSugerencia) || [];
        return acc;
    }, {});
    const maxRows = Math.max(...Object?.values(groupedSugerencias)?.map(group => group?.length));
    const options = informationInspection?.tipo?.opciones;
    const answers = informationInspection?.respuestas;
    const modules = informationInspection?.tipo?.tipo_modulos;

    return (
        <section className='form-register inspection'>
            <div className='row'>
                {datos.map(([clave, valor], index) => (
                    // ({(clave != 'id_inspeccion') ?                    
                        <div className='col col-12 col-md-6 col-lg-4' key={`${index}`}>
                            <div className="header-and-btn-end">
                                <CustomInputInfoSingleLine
                                    title={clave}
                                    value={valor}
                                />
                            </div>
                        </div>
                    // :''})
                ))}
            </div>
            <div className={`${+idType === 5 || +idType === 6 ? 'table-container-planta' : 'table-responsive'}`} >
                <table className="table table-bordered table-sm custom-table">
                { (+idType === 4 || +idType === 3 || +idType === 1) ?
                    <>{/* preguntas */}
                        <thead className="border-white">
                            <tr>
                                <th className="text-center title-table-inspection" >Pasos Críticos da Tarea</th>
                                <th className="text-center title-table-inspection col-1" >Peso</th>
                                <th className="text-center title-table-inspection col-2" >OK?</th>
                                <th className="text-start title-table-inspection" >Anomalías presentadas y acciones correctivas necesarias</th>
                            </tr>
                        </thead>
                        <tbody>
                            {informationInspection?.tipo?.tipo_modulos?.map((module) => {
                                return (
                                    <ModuleRow
                                        key={`module-${module.modulo.id_modulo}`}
                                        module={module.modulo}
                                        puntaje={module?.puntajeModulo}
                                        questions={module.preguntas}
                                        informationInspection={informationInspection}
                                        onChangeRespuesta={null}
                                        idType={idType}
                                    />
                                )
                            })}
                            <br /><br />
                            {/* Fila única con "Número de pasos" */}
                            {(+idType === 4) &&
                                <tr>
                                    <td>{extraStep.desPregunta}</td>
                                    <td className='text-center'>{extraStep.puntaje}</td>
                                    <td>
                                        <CustomInputInfoSingleLine
                                            value={informationInspection?.pasos}
                                        />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </>
                : (+idType === 2) ?
                    <>{/* preguntas */}
                        <tbody>
                            {informationInspection?.tipo?.tipo_modulos.map((module) => {
                                return (
                                    <ModuleRow
                                        key={`module-${module.modulo.id_modulo}`}
                                        module={module.modulo}
                                        puntaje={module?.puntajeModulo}
                                        questions={module.preguntas}
                                        informationInspection={informationInspection}
                                        idType={idType}
                                    />
                                )
                            })}
                        </tbody>
                    </>
                : (+idType === 5 || +idType === 6) ?
                    <>{/* preguntas */}
                        {modules?.map((tipo_modulo) => {
                            const module = tipo_modulo?.modulo;
                            const questions = tipo_modulo?.preguntas;
                            const lengthQuestion = (tipo_modulo?.preguntas?.length ?? 0) + 1;
                            return (
                                <React.Fragment key={`module-${module?.id_modulo}`}>
                                    <thead className="border-white">
                                        <tr>
                                            <th className="text-center title-table-inspection" style={{ width: "60px" }} rowSpan={2}>SENSO</th>
                                            <th className="text-center title-table-inspection d-none d-md-table-cell" style={{ width: "120px" }} rowSpan={2}>ÍTEM</th>
                                            <th className="text-center title-table-inspection d-md-none" style={{ width: "20px", fontSize: "0.8rem" }} rowSpan={2}>ÍTEM</th>
                                            <th className="text-center title-table-inspection col-2" style={{ width: "350px" }} rowSpan={2}>Descripción</th>
                                            <th className="text-center title-table-inspection" colSpan={5}>NOTA</th>
                                            <th className="text-center title-table-inspection" rowSpan={2}>OBSERVACIONES</th>
                                        </tr>
                                        <tr>
                                            {options?.map((row) => (
                                                <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>{row?.desc1}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={`module-${module?.id_modulo}`}>
                                            <td className="text-center title-table-inspection" style={{ width: "40px" }} rowSpan={lengthQuestion}>
                                                <label style={{ width: "50px", transform: 'rotate(-90deg)' }}>{module?.desModulo}</label>
                                            </td>
                                        </tr>
                                        {questions?.map((question) => {
                                            const pregunta_opcion = question.pregunta_opcion;
                                            const answer = answers?.find(e => +e.pregunta_id === +question.id_pregunta);
                                            return (
                                                <tr key={`question-${question?.id_pregunta}`}>
                                                    <td className="text-center">{question.desPregunta}</td>
                                                    <td className="text-center">{question.detallePregunta}</td>
                                                    {options.map((opt) => {
                                                        const item = pregunta_opcion?.find(e => +e.opcion_id === +opt.value);
                                                        return (
                                                            <td
                                                                key={opt.value}
                                                                className={`text-center ${+answer?.opcion_id === opt.value ? 'selected' : ''}`}
                                                                style={{ cursor: 'pointer', backgroundColor: +answer?.opcion_id === +opt.value ? 'lightgreen' : 'inherit' }}
                                                            >
                                                                {item?.desripcion}
                                                            </td>
                                                        );
                                                    })}
                                                    <td className="text-center">
                                                        <input
                                                            className="form-control custom-input"
                                                            placeholder="Comentarios"
                                                            value={answer?.observaciones || ""}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </React.Fragment>
                            );
                        })}      
                    </>
                : <h1 className='text-center title-page'>Pagina en mantenimiento</h1>
                }
                </table>
            </div>
            { (+idType === 4 || +idType === 3 || +idType === 1) ?
                <>{/* sugerencias */}
                    <div className="table-responsive-sm">
                        <table className="table table-bordered table-sm">
                            <thead className="border-white">
                                <tr>
                                    {suggestionType?.map(tipo => (
                                        <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end" style={{ background: "#BFBFBF", fontSize: "13px" }}>
                                            <div className="text-header">{tipo.desTipoSugerencia}</div>
                                            <button type='button' className="btn-outline-custom dark btn-header-end">
                                                <AddBoxIcon fontSize='small' />
                                            </button>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: maxRows })?.map((_, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {suggestionType?.map(tipo => (
                                            <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end">
                                                {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex] ? (
                                                    <div key={groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.id_sugerencia} className="text-header">
                                                        {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.desSugerencia}
                                                    </div>
                                                ) : null}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {/* plan de accion */}
                    <div className="table-responsive-sm">
                        <table className="table table-bordered table-sm">
                            <thead className="border-white">
                                <tr>
                                    <th className="text-center title-table-inspection" colSpan={666} >
                                        PLAN DE ACCIÓN
                                    </th>
                                </tr>
                                <tr>
                                    <th className="text-center title-table-inspection" >SEMANA</th>
                                    <th className="text-center title-table-inspection col-6" >ACCION</th>
                                    <th className="text-center title-table-inspection" >FECHA FIN</th>
                                    <th className="text-center title-table-inspection" >RESPONSABLE</th>
                                    <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                        <div className="text-header">ESTADO</div>
                                        <button className="btn-outline-custom dark btn-header-end" type='button'>
                                            <AddBoxIcon fontSize='small' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                                    return (
                                        <tr key={idxPlan}>
                                            <td className="text-center col-1">{plan.semana}</td>
                                            <td>{plan.desPlanAccion}</td>
                                            <td className="text-center">{plan.fechaFin}</td>
                                            <td className="text-center">{plan.responsable}</td>
                                            <td className="row-header-and-btn-end">
                                                <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            : (+idType === 2) ?
                <>
                    {/* plan de accion */}
                    <div className="table-responsive-sm">
                        <table className="table table-bordered table-sm">
                            <thead className="border-white">
                                <tr>
                                    <th className="text-center title-table-inspection" colSpan={666} >
                                        PLAN DE ACCIÓN
                                    </th>
                                </tr>
                                <tr>
                                    <th className="text-center title-table-inspection" >SEMANA</th>
                                    <th className="text-center title-table-inspection col-6" >ACCION</th>
                                    <th className="text-center title-table-inspection" >FECHA FIN</th>
                                    <th className="text-center title-table-inspection" >RESPONSABLE</th>
                                    <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                        <div className="text-header">ESTADO</div>
                                        <button className="btn-outline-custom dark btn-header-end" type='button'>
                                            <AddBoxIcon fontSize='small' />
                                        </button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                                    return (
                                        <tr key={idxPlan}>
                                            <td className="text-center col-1">{plan.semana}</td>
                                            <td>{plan.desPlanAccion}</td>
                                            <td className="text-center">{plan.fechaFin}</td>
                                            <td className="text-center">{plan.responsable}</td>
                                            <td className="row-header-and-btn-end">
                                                <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* Leyenda con las opciones */}
                    <div className="mb-5 col-12 col-md-4" style={{border: "solid 1px black",borderRadius: "25px", padding: "10px"}}>
                        <table className="table table-bordered table-sm custom-table">
                            <thead>
                                <tr>
                                    <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                        Leyenda de Notas
                                    </th>
                                </tr>
                                <tr>
                                    {options?.map((row) => (
                                        <th key={row.label} className="text-center col-1" style={{ width: "30px" }}>
                                            {row?.desc2}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {options?.map((row) => (
                                        <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                            {row?.label}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            : (+idType === 5 || +idType === 6) ?
                <>
                    {/* resumen */}
                    <div className="mb-5 col-12 col-md-4"
                        style={{
                            border: "solid 1px black",
                            borderRadius: "25px",
                            padding: "10px",
                        }}
                    >
                        <span
                            style={{
                                fontSize: "13px",
                                display: "flex",
                                justifyContent: "space-between",
                                borderTop: "solid 1px black"
                            }}
                        >
                            <label>Nota 5S:</label>
                            <label style={{ fontWeight: "bold", }}>
                                {informationInspection?.puntaje}
                            </label>
                        </span>
                        <span
                            style={{
                                fontSize: "13px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <label>% Cumplimiento:</label>
                            <label style={{ fontWeight: "bold", }}>
                                {`${informationInspection?.porcentaje ? (informationInspection?.porcentaje * 100) : ''}%`}
                            </label>
                        </span>
                    </div>
                    {/* Nueva sección similar a la última */}
                    <div className="mb-5 col-12 col-md-4"
                        style={{
                            border: "solid 1px black",
                            borderRadius: "25px",
                            padding: "10px",
                        }}
                    >
                        <table className="table table-bordered table-sm custom-table">
                            <thead>
                                <tr>
                                    <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                        Leyenda de Notas
                                    </th>
                                </tr>
                                <tr>
                                    {options?.map((row) => (
                                        <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                            {row?.desc3}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {options?.map((row) => (
                                        <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                            {row?.label}
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            : <h1 className='text-center title-page'>Pagina en mantenimiento</h1>
            }
        </section>
    )
}