import React, { useState } from 'react'
import { ButtonGroup, IconButton } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import CustomCheck from '../../General/CustomCheck';
import CustomTitleTable from '../../General/CustomTitleTable';
import { sortDataList, } from '../../../../utils/function';
import _ from 'lodash';

const columns = [
    { id: 'id_planta', label: '#', width: 20, align: 'center', },
    { id: 'desPlanta', label: <CustomTitleTable title='Planta' />, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'sel', label: <CustomTitleTable title='actions' />, width: 20, align: 'center', },
];

const BodyPlants = (props) => {
    const { data, dataExists, dataSelected, setDataSelected, activeTab, id, } = props;

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

    const handleChangeCheck = (check, info) => {
        let updData = _.cloneDeep(dataSelected);
        if (updData === null) {
            updData = ([]);
        }

        const idxExists = updData?.findIndex(e => +e[id] === +info[id]);
        if (check === false) {
            updData = updData?.filter((e, i) => i !== idxExists)
        } else {
            if (idxExists === -1) {
                updData.push(info);
            }
        }
        setDataSelected(updData);
    }

    return (
        <div className="frame-table mt-2">
            <div className="table-responsive body-table small">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            return (
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : col.id === "sel" ?
                                                        <CustomCheck
                                                            id={`chk-sede-${row[id]}`}
                                                            handleChangeCheck={handleChangeCheck}
                                                            info={row}
                                                            checked={
                                                                (activeTab > 0 ?
                                                                    (dataSelected?.find(e => +e[id] === +row[id]) ||
                                                                        dataExists?.find(e => +e[id] === +row[id]))
                                                                    : dataSelected?.find(e => +e[id] === +row[id])
                                                                ) ? true : false}
                                                        />
                                                        : (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Editar tipo de legajo"
                                                                // onClick={() => handleOnChange(idxRow, row, 'edit', 1)}
                                                                >
                                                                    <EditIcon className="icon-button-table" color={'primary'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Eliminar tipo de legajo"
                                                                // onClick={() => saveArea(idxRow, row, 2)}
                                                                >
                                                                    <DeleteIcon className="icon-button-table" color={'error'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodyPlants