import React, { useEffect, useState, } from 'react'
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { simpleAlerts } from '../../../../utils/alerts';
import { validationMessages } from '../../../../message/validations';
import CustomInputSingleLine from '../../../components/General/CustomInputSingleLine';
import CustomSelectSingleLine from '../../../components/General/CustomSelectSingleLine';
import CustomInputInfoSingleLine from '../../../components/General/CustomInputInfoSingleLine';
import AddWorker from '../../../components/Workers/AddWorker/AddWorker';
import _ from 'lodash';

const DTO = ({ codigo, handleCancel, handleOpenAddWorker, dataAreas, valuePlant, valueArea, setValueArea, dataSubAreas,
    suggestionType, stateActionPlan, informationInspection, setInformationInspection, dataCompany, setDataCompany, valueCompany, setValueCompany, perfil_id }) => {
    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            evaluado: null,
            evaluado_id: null,
            supervisor: null,
            supervisor_id: null,
            evaluador: null,
            evaluador_id: null,
        }));
    }, [valuePlant]);

    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            evaluado: null,
            evaluado_id: null,
            supervisor: null,
            supervisor_id: null,
            evaluador: null,
            evaluador_id: null,
        }));
    }, [valueArea]);

    const onChangeRespuesta = ({ info, value, key, }) => {
        let updData = _.cloneDeep(informationInspection)
        const respuestaIndex = updData.respuestas.findIndex(respuesta => +respuesta.pregunta_id === +info.id_pregunta);
        const keyAnswer = info.tipo_respuesta.desc2;
        const opciones_id = info.tipo_respuesta.desTipoRespuesta === "opt" ? info?.opciones_id : null;
        const id_pregunta = info.id_pregunta;
        const tipoRespuesta_id = info.tipoRespuesta_id;

        let respueta = {
            inspeccion_id: null,
            pregunta_id: null,
            tipoRespuesta_id: null,
            opciones_id: null,
            opcion_id: null,
            puntaje: null,
            descipcion: null,
            anomalias: null,
        };
        respueta["pregunta_id"] = id_pregunta
        respueta["tipoRespuesta_id"] = tipoRespuesta_id
        respueta["opciones_id"] = opciones_id
        if (info.tipo_respuesta.desTipoRespuesta === 'opt') {
            respueta["puntaje"] = info.puntaje
        }

        const opcion = info.opciones.find(e => +e.value === +value);
        if (respuestaIndex !== -1) {
            updData.respuestas[respuestaIndex][key === 'answer' ? keyAnswer : key] = value;
        } else {
            if (key === 'answer') {
                respueta[keyAnswer] = value
                updData.respuestas.push(respueta);
            } else {
                respueta[key] = value;
                updData.respuestas.push(respueta);
            }
        }
        setInformationInspection(updData);
        if (key === 'answer') {
            onChangePuntajes({ opcion: opcion, puntaje: info.puntaje, id_pregunta: id_pregunta, datos: updData })
        }
    }

    //------------------------------------------------------------------------------------- Pregunta estatica de pasos-------------
    const [puntajeExtraStep, setPuntajeExtraStep] = useState(0);
    const extraStep = {
        id_pregunta: 'extra-step',
        desPregunta: 'Número de pasos',
        puntaje: "30.00",
        tipo_respuesta: {
            desTipoRespuesta: 'opt',
            opciones: [
                { value: 'Ok', label: 'Ok' },
                { value: 'NO', label: 'N/O' },
                { value: 'NA', label: 'N/A' },
            ],
            desc2: 'answer',
        },
    };

    // Manejador de cambio para el select
    const handleExtraStepChange = (event) => {
        const selectedValue = event.target.value;
        const isOk = selectedValue === 'Ok';
        const newValuePasos = isOk ? parseFloat(extraStep.puntaje) : 0;
        setPuntajeExtraStep(newValuePasos);
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            pasos: newValuePasos,
        }));
        onChangePuntajes({ datos: informationInspection, valuePasos: newValuePasos });
    };
    //---------------------------------------------------------------------------FIN Pregunta estatica de pasos-------------


    const onChangePuntajes = ({ datos, valuePasos = puntajeExtraStep }) => {
        const respuestas = datos.respuestas;
        const tipo_modulos = datos.tipo.tipo_modulos;

        const findPregunta = (id) => {
            for (const modulo of tipo_modulos) {
                const pregunta = modulo.preguntas.find(p => +p.id_pregunta === +id);
                if (pregunta) {
                    return pregunta;
                }
            }
            return null;
        };

        // Combina los dos cálculos en un solo reduce
        //=SUMAR.SI(H13:H14;"OK";G13:G14)+SUMAR.SI(H16:H17;"OK";G16:G17)
        let puntajeSum = respuestas.reduce((sum, e) => {
            const pregunta = findPregunta(e.pregunta_id);
            const respuestaIsOk = pregunta?.opciones?.find(opt => +opt.value === +e.opcion_id)?.label?.toUpperCase() !== 'NOK';
            return sum + (respuestaIsOk ? +e.puntaje : 0);
        }, 0);

        //=SUMAR.SI(H13:H14;"<>N/A";G13:G14)+SUMAR.SI(H16:H17;"<>N/A";G16:G17)+30
        let puntajePosibleSum = tipo_modulos.reduce((acc, modulo) => {
            modulo.preguntas.forEach(pregunta => {
                const respuesta = respuestas?.find(e => +e.pregunta_id === +pregunta.id_pregunta);
                const respuestaIsNotNA = pregunta?.opciones?.find(opt => +opt.value === +respuesta?.opcion_id)?.label?.toUpperCase() !== 'N/A';
                acc += respuestaIsNotNA ? +pregunta.puntaje : 0;
            });
            return acc;
        }, 0);


        if (codigo === 'DTO') {
            puntajePosibleSum += 30
            puntajeSum += valuePasos;
        }

        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            puntaje: puntajeSum?.toFixed(2),
            puntajePosible: puntajePosibleSum?.toFixed(2),
            porcentaje: (puntajeSum / puntajePosibleSum)?.toFixed(2),
        }));
    };

    // Agrupar sugerencias por tipoSugerencia_id
    const groupedSugerencias = suggestionType?.reduce((acc, tipo) => {
        acc[tipo.id_tipoSugerencia] = informationInspection?.sugerencias?.filter(sug => +sug.tipoSugerencia_id === +tipo.id_tipoSugerencia) || [];
        return acc;
    }, {});

    // Obtener la longitud máxima de sugerencias en cualquier grupo
    const maxRows = Math.max(...Object?.values(groupedSugerencias)?.map(group => group.length));

    // Función para agregar una nueva sugerencia
    const handleAddSugerencia = (tipoId) => {
        const nuevaSugerencia = prompt('Ingrese la nueva sugerencia:');

        if (nuevaSugerencia !== null) {  // Verificar si no se canceló
            const newSugerencia = {
                desSugerencia: nuevaSugerencia,
                tipoSugerencia_id: tipoId,
            };

            setInformationInspection(prev => ({
                ...prev,
                sugerencias: [...prev.sugerencias, newSugerencia]
            }));
        }
    };

    const [deletedSugerencias, setDeletedSugerencias] = useState([]);
    // Función para eliminar una sugerencia
    const handleDeleteSugerencia = (desSugerencia) => {
        const sugerenciaToDelete = informationInspection.sugerencias.find(sug => sug.desSugerencia === desSugerencia);

        if (sugerenciaToDelete) {
            // Almacena la sugerencia eliminada en el estado
            setDeletedSugerencias(prev => [...prev, sugerenciaToDelete]);

            // Actualiza la lista de sugerencias
            setInformationInspection(prev => ({
                ...prev,
                sugerencias: prev.sugerencias.filter(sug => sug.desSugerencia !== desSugerencia)
            }));
        }
    };

    const [isPromptOpen, setIsPromptOpen] = useState(false);
    const [isStrictClose, setIsStrictClose] = useState(false);
    const openPrompt = (strictClose) => {
        setIsStrictClose(strictClose); //? true es para cerrar solo por boton cerrar, false se cierra precionando en cualquier parte
        setIsPromptOpen(true);
    };

    const closePrompt = () => {
        setIsPromptOpen(false);
    };

    const handleAddActionPlan = () => {
        openPrompt(true);
    };

    const handleFormSubmitActionPlan = (formData) => {
        formData = { ...formData, estadoPlan_id: 1 };
        setInformationInspection(prev => ({
            ...prev,
            planes_accion: [...prev.planes_accion, formData]
        }));
    };

    const [deletedActionPlans, setDeletedActionPlans] = useState([]);
    // Función para eliminar un plan de acción
    const handleDeleteActionPlans = (desPlanAccion) => {
        const ActionPlanToDelete = informationInspection.planes_accion.find(sug => sug.desPlanAccion === desPlanAccion);

        if (ActionPlanToDelete) {
            // Almacena la sugerencia eliminada en el estado
            setDeletedActionPlans(prev => [...prev, ActionPlanToDelete]);

            // Actualiza la lista de sugerencias
            setInformationInspection(prev => ({
                ...prev,
                planes_accion: prev.planes_accion.filter(sug => sug.desPlanAccion !== desPlanAccion)
            }));
        }
    };

    return (
        <section className='form-register inspection'>
            <div className='row justify-content-center'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Empresa'}
                        placeholder={'Seleccionar Empresa'}
                        options={dataCompany}
                        value={dataCompany?.find(option => option?.id_empresa === valueCompany)}
                        onChange={(value, event) => {
                                const selectedOption = dataCompany?.find(option => option.value === value);
                                setValueCompany(selectedOption?.id_empresa);
                                setInformationInspection({
                                    ...informationInspection,
                                    empresa_id: selectedOption?.id_empresa,
                                    desEmpresa: selectedOption?.desEmpresa,
                                });
                            }
                        }
                        readonly={+perfil_id !== 1 && +perfil_id !== 3}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Area'}
                        placeholder={'Seleccionar Area'}
                        options={dataAreas}
                        value={dataAreas?.find(option => option.id_area === valueArea)}
                        onChange={(value, event) => {
                            const selectedOption = dataAreas?.find(option => option.value === value);
                            setValueArea(selectedOption?.id_area); // Aquí actualizas el área seleccionada
                            setInformationInspection({
                                ...informationInspection,
                                area_id: selectedOption?.id_area,
                                areaSubArea_id: null,
                                desArea: selectedOption?.desArea,
                                desSubArea: null
                            });
                        }}
                    />
                </div>

                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Sub-Area'}
                        placeholder={'Selecionar SubArea'}
                        options={dataSubAreas}
                        required={true}
                        value={dataSubAreas?.find(option => option.id_areaSubArea === informationInspection?.areaSubArea_id)}
                        onChange={(value, event) => {
                            const selectedOption = dataSubAreas?.find(option => option.value === value);
                            setInformationInspection({
                                ...informationInspection,
                                areaSubArea_id: selectedOption.id_areaSubArea,
                                desSubArea: selectedOption.desSubArea
                            });
                        }}
                    />
                </div>

            </div>
            <div className='row'>
                <div className='col col-sm-12 col-md-6 col-lg-4 d-flex'>
                    <div style={{ width: "70%" }}>
                        <CustomInputSingleLine
                            title={"Fecha Evaluación"}
                            type={"date"}
                            value={informationInspection?.fecha}
                            name={"fecha"}
                            style={{ width: "80%" }}
                            onChange={(e) => setInformationInspection({ ...informationInspection, fecha: e.target.value })}
                            required={true}
                        />
                    </div>
                    <div style={{ width: "40%" }}>
                        <CustomInputSingleLine
                            type={"time"}
                            value={informationInspection?.hora}
                            name={"hora"}
                            onChange={(e) => setInformationInspection({ ...informationInspection, hora: e.target.value })}
                            required={true}
                        />
                    </div>
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputSingleLine
                        title={"PETS"}
                        placeholder={"Ingrese el PETS"}
                        name={"pets"}
                        required={true}
                        value={informationInspection?.pets}
                        onChange={(e) => setInformationInspection({ ...informationInspection, pets: e.target.value })}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Evaluado"}
                                name={"evaluado"}
                                required={true}
                                value={informationInspection?.evaluado?.trabajador || ""}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "evaluado_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Supervisor del Evaluado"}
                                type={"text"}
                                name={"supervisor"}
                                style={{ width: "95%" }}
                                required={true}
                                value={informationInspection?.supervisor?.trabajador}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "supervisor_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Evaluador"}
                                type={"text"}
                                name={"evaluador"}
                                style={{ width: "95%" }}
                                required={true}
                                value={informationInspection?.evaluador?.trabajador}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "evaluador_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
            </div>
            <div className='row justify-content-end'>
                <div className='col-sm-12 col-md-4 col-lg-2'>
                    <CustomInputInfoSingleLine
                        title={"Puntaje"}
                        value={informationInspection?.puntaje || 0}
                        name={"puntaje"}
                    />
                </div>
                {/* <div className='col-sm-12 col-md-4 col-lg-2'>
                    <CustomInputInfoSingleLine
                        title={"Puntaje Posible"}
                        value={informationInspection?.puntajePosible || 0}
                        name={"puntajePosible"}
                    />
                </div>
                <div className='col-sm-12 col-md-4 col-lg-2'>
                    <CustomInputInfoSingleLine
                        title={"%"}
                        value={`${informationInspection?.porcentaje ? informationInspection?.porcentaje * 100 : 0} %`}
                        name={"porcentaje"}
                    />
                </div> */}
            </div>
            {/* preguntas */}
            <div className="table-responsive">
                <table className="table table-bordered table-sm custom-table">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" >Pasos Críticos da Tarea</th>
                            <th className="text-center title-table-inspection col-1" >Peso</th>
                            <th className="text-center title-table-inspection col-2" >OK?</th>
                            <th className="text-start title-table-inspection" >Anomalías presentadas y acciones correctivas necesarias</th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.tipo?.tipo_modulos.map((module) => {
                            return (
                                <ModuleRow
                                    key={`module-${module.modulo.id_modulo}`}
                                    module={module.modulo}
                                    questions={module.preguntas}
                                    informationInspection={informationInspection}
                                    onChange={onChangeRespuesta}
                                />
                            )
                        })}
                        {/* Fila única con "Número de pasos" */}
                        <br /><br />
                        {(codigo === 'DTO') &&
                            <tr>
                                <td>{extraStep.desPregunta}</td>
                                <td className='text-center'>{extraStep.puntaje}</td>

                                <td>
                                    <select
                                        className="form-control custom-select"
                                        required
                                        onChange={handleExtraStepChange}
                                    >
                                        <option value="">Seleccione...</option>
                                        {extraStep.tipo_respuesta.opciones && Array.isArray(extraStep.tipo_respuesta.opciones) ? (
                                            extraStep.tipo_respuesta.opciones.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No hay opciones disponibles</option>
                                        )}
                                    </select>
                                </td>

                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            {/* sugerencias */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            {suggestionType?.map(tipo => (
                                <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end" style={{ background: "#BFBFBF", fontSize: "13px" }}>
                                    <div className="text-header">{tipo.desTipoSugerencia}</div>
                                    <button
                                        type='button'
                                        className="btn-outline-custom dark btn-header-end"
                                        onClick={() => handleAddSugerencia(tipo.id_tipoSugerencia)}
                                    >
                                        <AddBoxIcon fontSize='small' />
                                    </button>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: maxRows })?.map((_, rowIndex) => (
                            <tr key={rowIndex}>
                                {suggestionType?.map(tipo => (
                                    <th key={tipo.id_tipoSugerencia} className="row-header-and-btn-end">
                                        {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex] ? (
                                            <>
                                                <div key={groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.id_sugerencia} className="text-header">
                                                    {groupedSugerencias[tipo.id_tipoSugerencia][rowIndex]?.desSugerencia}
                                                </div>
                                                <button type='button' className="btn-outline-custom danger btn-header-end"
                                                    onClick={() => handleDeleteSugerencia(groupedSugerencias[tipo.id_tipoSugerencia][rowIndex].desSugerencia)}
                                                >
                                                    <DeleteIcon style={{ fontSize: "15px" }} />
                                                </button>
                                            </>
                                        ) : null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/* plan de accion */}
            <div className="table-responsive-sm">
                <table className="table table-bordered table-sm">
                    <thead className="border-white">
                        <tr>
                            <th className="text-center title-table-inspection" colSpan={666} >
                                PLAN DE ACCIÓN
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center title-table-inspection" >SEMANA</th>
                            <th className="text-center title-table-inspection col-6" >ACCION</th>
                            <th className="text-center title-table-inspection" >FECHA FIN</th>
                            <th className="text-center title-table-inspection" >RESPONSABLE</th>
                            <th className="text-center title-table-inspection col-2 row-header-and-btn-end" >
                                <div className="text-header">ESTADO</div>
                                <button className="btn-outline-custom dark btn-header-end" type='button'
                                    onClick={() => handleAddActionPlan()}
                                >
                                    <AddBoxIcon fontSize='small' />
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {informationInspection?.planes_accion?.map((plan, idxPlan) => {
                            return (
                                <tr key={idxPlan}>
                                    <td className="text-center col-1">{plan.semana}</td>
                                    <td>{plan.desPlanAccion}</td>
                                    <td className="text-center">{plan.fechaFin}</td>
                                    <td className="text-center">{plan.responsable?.trabajador}</td>
                                    <td className="row-header-and-btn-end">
                                        <div className="text-header">{stateActionPlan?.find(e => +e.value === +plan.estadoPlan_id)?.label}</div>
                                        <button
                                            type='button'
                                            className="btn-outline-custom danger btn-header-end"
                                            onClick={() => handleDeleteActionPlans(plan.desPlanAccion)}
                                        >
                                            <DeleteIcon style={{ fontSize: "15px" }} />
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='d-flex justify-content-center gap-2 mb-3' style={{ marginTop: '0.5rem' }}>
                <button className='btn btn-lg btn-outline-danger' type="button" onClick={handleCancel}>
                    Cancelar
                </button>
                <button className='btn btn-lg btn-outline-success' type="submit">
                    Guardar
                </button>

            </div>
            <div>
                {isPromptOpen && (
                    <FormPlanAction
                        onClose={closePrompt}
                        onSubmit={handleFormSubmitActionPlan}
                        isStrictClose={isStrictClose}
                        valuePlant={valuePlant}
                    />
                )}
            </div>
        </section >
    )
}

const ModuleRow = ({ module, questions, informationInspection, onChange }) => {
    return (
        <>
            <tr key={`module-${module.id_modulo}`}>
                <td colSpan={999} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`Asunto: ${module.desModulo}`}</td>
            </tr>
            {questions.map((question) => {
                const answer = informationInspection.respuestas.find(e => +e.pregunta_id === +question.id_pregunta);
                return (
                    <QuestionRow
                        key={`question-${question.id_pregunta}`}
                        question={question}
                        answer={answer}
                        onChange={onChange}
                    />
                );
            })}
        </>
    );
};

const QuestionRow = ({ question, answer, onChange }) => {
    const tipeRespuesta = question.tipo_respuesta;
    const options = question.opciones;
    return (
        <tr>
            <td>{question.desPregunta}</td>
            <td className='text-center'>{question.puntaje}</td>
            <td>
                {tipeRespuesta.desTipoRespuesta === 'opt' ?
                    <select
                        className="form-control custom-select"
                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        required
                    >
                        <option value="">Seleccione...</option>
                        {options.map((option) => {
                            return (
                                <option value={option.value}>{option.label}</option>
                            )
                        })}
                    </select>
                    : <input
                        className="form-control custom-input"
                        placeholder="Ingrese valor"
                        type={tipeRespuesta.desTipoRespuesta}
                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        required
                    />
                }
            </td>
            <td>
                <input
                    className="form-control custom-input"
                    type="text"
                    onChange={(e) => onChange({ info: question, value: e.target.value, key: "anomalias" })}
                    value={answer?.anomalias || ""}
                />
            </td>
        </tr>
    );
};

const FormPlanAction = ({ onClose, onSubmit, isStrictClose, valuePlant, }) => {
    const [openAddWorkersModal, setOpenAddWorkersModal] = useState(false);
    const [semana, setSemana] = useState('');
    const [desPlanAccion, setDesPlanAccion] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [responsableId, setResponsableId] = useState('');
    const [responsable, setResponsable] = useState({});
    const [workersSelected, setWorkersSelected] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validación manual de campos requeridos
        if (!semana || !desPlanAccion || !fechaFin || !responsableId) {
            alert('Todos los campos son obligatorios.');
            return;
        }

        onSubmit({ semana, desPlanAccion, fechaFin, responsable, responsable_id: responsableId, });
        handleClose(); // Cierra el modal
    };

    const handleClose = () => {
        setSemana('');
        setDesPlanAccion('');
        setFechaFin('');
        setResponsableId('');
        onClose();
    };

    const handleOverlayClick = (e) => {
        if (!isStrictClose && e.target.className.includes('modal-overlay-prompt')) {
            handleClose();
        }
    };

    const handleOpenAddWorker = ({ key }) => {
        if (!valuePlant) {
            simpleAlerts({ message: validationMessages.planta, type: "warning" });
            return;
        }
        setOpenAddWorkersModal(true);
    }

    useEffect(() => {
        if (workersSelected && workersSelected?.length > 0) {
            setResponsableId(workersSelected[0]?.id_persona);
            setResponsable(workersSelected[0]);
        }
    }, [workersSelected])

    return (
        <div className="modal-overlay-prompt" onClick={handleOverlayClick}>
            <AddWorker
                open={openAddWorkersModal}
                setOpen={setOpenAddWorkersModal}
                typeSelection={"SINGLE"}
                valueTypeWorker={1}
                valuePlantAux={valuePlant}
                workersSelected={workersSelected}
                setWorkersSelected={setWorkersSelected}
            />
            <div className="input-container-prompt">
                <h2 className="title-prompt">Nuevo Plan de Acción</h2>
                {/* <form onSubmit={handleSubmit}> */}
                <div className="form-group-prompt">
                    <label htmlFor="semana">Semana:</label>
                    <input
                        type="number"
                        id="semana"
                        placeholder="Ingrese semana"
                        value={semana}
                        onChange={(e) => setSemana(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="desPlanAccion">Descripción del Plan:</label>
                    <input
                        type="text"
                        id="desPlanAccion"
                        placeholder="Ingrese descripción"
                        value={desPlanAccion}
                        onChange={(e) => setDesPlanAccion(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="fechaFin">Fecha de Fin:</label>
                    <input
                        type="date"
                        id="fechaFin"
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group-prompt">
                    <label htmlFor="responsableId">Responsable:</label>
                    <div className="header-and-btn-end">
                        <input
                            type="text"
                            id="responsable"
                            value={responsable?.trabajador}
                            required
                            readOnly={true}
                        />
                        <button type='button'
                            className="btn-outline-custom btn-submit-prompt btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "responsable_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className="modal-buttons-prompt">
                    <button type="button" className="btn-submit-prompt" onClick={handleSubmit}>Agregar</button>
                    <button type="button" onClick={handleClose} className="btn-cancel-prompt">Cancelar</button>
                </div>
                {/* </form> */}
            </div>
        </div>
    );
};

export default DTO;