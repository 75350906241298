import React from 'react'

const QuestionRow = ({ question, answer, onChange, idType }) => {
    const tipeRespuesta = question.tipo_respuesta;
    const options = question.opciones;
    return (
        <tr>
            <td>{question.desPregunta}</td>
            <td className='text-center'>{question.puntaje}</td>
            <td>
                {tipeRespuesta.desTipoRespuesta === 'opt' && (+idType === 4 || +idType === 3 || +idType === 1) ?
                    <select
                        className="form-control custom-select"
                        onChange={(e) => onChange({ info: question, value: e.target.value, key: "answer" })}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        disabled
                    >
                        <option value="">Seleccione...</option>
                        {options.map((option) => {
                            return (
                                <option value={option.value}>{option.label}</option>
                            )
                        })}
                    </select>
                : tipeRespuesta.desTipoRespuesta === 'opt' && +idType === 2 ?
                    <div className="form-control custom-radio-group in-line">
                        {options.map((option) => {
                            return (
                                <div key={option.value} className="custom-radio-option">
                                    <input
                                        type="radio"
                                        id={`${question.id_pregunta}_${option.value}`}
                                        value={option.value}
                                        checked={answer ? answer[tipeRespuesta.desc2] === option.value : false}
                                        readOnly
                                    />
                                    <label htmlFor={`${question.id_pregunta}_${option.value}`}>{option.label}</label>
                                </div>
                            )
                        })}
                    </div>
                : 
                    <input
                        className="form-control custom-input"
                        placeholder="Ingrese valor"
                        type={tipeRespuesta.desTipoRespuesta}
                        value={answer ? answer[tipeRespuesta.desc2] : ""}
                        readOnly
                        disabled
                    />
                }
            </td>
            {(+idType === 4 || +idType === 3 || +idType === 1) &&
                <td>
                    <input
                        className="form-control custom-input"
                        type="text"
                        value={answer?.anomalias || ""}
                        readOnly
                        disabled
                    />
                </td>
            }
        </tr>
    );
};

export default QuestionRow;