import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import { ButtonGroup, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ConstructionIcon from '@mui/icons-material/Construction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import SubAreaService from '../../../../../services/subArea';
import CustomInput from '../../../General/CustomInput';
import CustomSelect from '../../../General/CustomSelect';
import { sortDataList, getValueFromPath } from "../../../../../utils/function";
import { CODES } from '../../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { simpleAlerts } from '../../../../../utils/alerts';
import { validationMessages } from '../../../../../message/validations';
import { errorsMessages } from '../../../../../message/errors';
import CustomCheck from '../../../General/CustomCheck';
import { titlesSpinner } from '../../../../../message/titlesSpinner';
import { titlesAlerts } from '../../../../../message/titlesAlerts';

const columns = [
    { id: '#', label: '#', width: 20, align: 'center', },
    { id: 'desSubArea', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Sub-Área</>, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'estado', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Estado</>, width: 100, align: 'left', order: true, type: "select", edit: true, },
    { id: 'actions', label: <ConstructionIcon key="icon" style={{ fontSize: "18px", }} />, width: 20, align: 'center', },
    { id: 'sel', label: <ConstructionIcon key="icon" style={{ fontSize: "18px", }} />, width: 20, align: 'center', },
];

const columnsMap = {
    estado: 'estado.desEstado',
};

const BodySubArea = (props) => {
    const { states, getInfo, data, setData, dataExists, dataSelected, setDataSelected, id } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

   /* const handleOnChange = (index, info, key, value) => {
        let updData = _.cloneDeep(data);
        const idxTmp = updData.findIndex(e => +e[id] === +info[id]);
        const idx = (idxTmp === -1 ? index : idxTmp);

        // if (idx !== -1) {
        if (key === 'estado') {
            updData[idx][key] = states?.find(e => +e.value === value);
            updData[idx]["estado_id"] = value;
        } else {
            updData[idx][key] = value;
        }
        // }
        return setData(updData);
    }*/

    const handleOnChange = (index, info, key, value) => {
        let updData = _.cloneDeep(data);
      
        const idxTmp = updData.findIndex(e => +e[id] === +info[id]);
        const idx = (idxTmp === -1 ? index : idxTmp);

        if (key === 'edit' && value === 0) {
            // Eliminar la fila si se cancela la edición
            updData.splice(idx, 1); 
        } else {
            // Actualizar los datos según la edición
            if (key === 'estado') {
                updData[idx][key] = states?.find(e => +e.value === value);
                updData[idx]["estado_id"] = value;
            } else {
                updData[idx][key] = value;
            }
        }
      
        setData(updData); // Actualizar el estado de los datos
    }
    
    
    const saveSubArea = async (idx, info, estado_id) => {
        if (!info[id] && estado_id === 2) {
            let updData = _.cloneDeep(data);
            setData(updData?.filter((e, index) => index !== idx));
        } else {
            if (!info?.desSubArea || info?.desSubArea === "") {
                simpleAlerts({ message: validationMessages.desSubArea });
                return;
            }
            if (!estado_id || estado_id === 0) {
                simpleAlerts({ message: validationMessages.estado });
                return;
            }

            const resultSwal = await Swal.fire({
                title: `${estado_id !== 1 ? titlesAlerts.confirm_subArea_area_inactive : titlesAlerts.confirm_subArea_area_inactive} (${info?.desSubArea})`,
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#229954", // "#3085d6",
                confirmButtonText: "Si!",
                cancelButtonColor: "#d33",
                reverseButtons: true,
            });

            if (resultSwal.isConfirmed) {
                try {
                    info = { ...info, estado_id };
                    handleOpenLoader(true);
                    handleTitleLoader(titlesSpinner.subArea_save);
                    const result = await SubAreaService.saveOrUpdate(info);
                    if (result.status === CODES.SUCCESS_200) {
                        getInfo();
                        simpleAlerts({ message: result?.data?.message, type: "success", })
                    } else {
                        simpleAlerts({ message: errorsMessages.subArea_save, type: "error" })
                    }
                    return []
                } catch (error) {
                    console.log(`Error en sub-área saveOrUpdate: ${error}`);
                } finally {
                    handleOpenLoader(false);
                    handleTitleLoader(null);
                }
            }
        }
    }

    const handleChangeCheck = (check, info) => {
        let updData = _.cloneDeep(dataSelected);
        if (!updData) {
            updData = ([]);
        }

        const idxExists = updData?.findIndex(e => +e[id] === +info[id]);
        if (check === false) {
            updData = updData?.filter((e, i) => i !== idxExists)
        } else {
            if (idxExists === -1) {
                updData.push(info);
            }
        }
        setDataSelected(updData);
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            const colorCol =
                                +row.estado_id !== 1 ?
                                    'col-disabled'
                                    : dataExists?.find(e => +e[id] === +row[id]) ?
                                        'col-assigned'
                                        : '';
                            return (
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align} ${colorCol}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : +row.edit === 1 ?
                                                        (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Guardar sub-área"
                                                                    onClick={() => saveSubArea(idxRow, row, row.estado_id)}
                                                                >
                                                                    <SaveIcon className="icon-button-table" color={'success'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Cancelar edición de sub-área"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 0)}
                                                                >
                                                                    <CancelIcon className="icon-button-table" color={'warning'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : col.edit ?
                                                                (col.type === 'select' ?
                                                                    <CustomSelect
                                                                        placeholder={'Estado'}
                                                                        options={states}
                                                                        value={value}
                                                                        onChange={(value, event) => handleOnChange(idxRow, row, 'estado', +value)}
                                                                    />
                                                                    : <CustomInput
                                                                        placeholder={'Nombre de sub-área'}
                                                                        value={value}
                                                                        type={col.type}
                                                                        onChange={(e) => handleOnChange(idxRow, row, col.id, e.target.value)}
                                                                    />
                                                                )
                                                                : value)
                                                        : (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Editar Sub-Área"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 1)}
                                                                >
                                                                    <EditIcon className="icon-button-table" color={'primary'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Eliminar Sub-Área"
                                                                    onClick={() => saveSubArea(idxRow, row, 2)}
                                                                >
                                                                    <DeleteIcon className="icon-button-table" color={'error'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : col.id === "sel" ?
                                                                <CustomCheck
                                                                    id={`chk-body-${id}-${row[id]}`}
                                                                    handleChangeCheck={handleChangeCheck}
                                                                    info={row}
                                                                    checked={(dataSelected?.find(e => +e[id] === +row[id])) ? true : false}
                                                                />
                                                                : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodySubArea