import React, { } from 'react';
import { Select } from "antd";
const { Option } = Select;

const CustomSelect = (props) => {
    const { mode, title, readonly, placeholder, width, size, options, value, name, onChange, required = false, nameKey = "" } = props;

    return (
        <div>
            {title &&
                <label className="form-label title-legend">
                    {title}
                </label>
            }
            <Select
                mode={mode || "single"}
                maxTagCount={'responsive'}
                //maxTagCount={1}
                disabled={readonly || false}
                className="select"
                size={size || 'middle'}
                placeholder={placeholder || 'Seleccionar'}
                optionFilterProp="children"
                showSearch
                name={name || ''}
                popupClassName='my-drop-down'
                value={value || 0}
                onChange={onChange}
                style={{ width: width || '100%' }}
                required={required || false}
            >
                <Option value={0}><em>Seleccionar</em></Option>
                {options?.map((opt, idx) => {
                    return (
                        <Option key={nameKey + opt?.value?.toString() + opt?.label?.replace(/\s+/g, '')} value={opt?.value}>
                            {opt?.label}
                        </Option>
                    )
                })}
            </Select>
        </div>
    );
};

export default CustomSelect;