import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import { ButtonGroup, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ConstructionIcon from '@mui/icons-material/Construction';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PlantService from '../../../services/plant';
import UserService from '../../../services/user';
import CustomInput from '../General/CustomInput';
import CustomSelect from '../General/CustomSelect';
import CustomTitleTable from '../General/CustomTitleTable';
import { sortDataList, getValueFromPath, filterData } from "../../../utils/function";
import { CODES } from '../../../utils/codesHTTP';
import { titlesAlerts } from '../../../message/titlesAlerts';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { simpleAlerts } from '../../../utils/alerts';
import Swal from 'sweetalert2';
import _ from 'lodash';

const fieldSearch = ['trabajador', 'usuario'];
const columns = [
    { id: 'id_usuario', label: '#', width: 50, align: 'center', },
    { id: 'trabajador', label: <CustomTitleTable title='Trabajador' />, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'usuario', label: <CustomTitleTable title='Usuario' />, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'perfil_id', label: <CustomTitleTable title='Perfil' />, width: 100, align: 'left', order: true, type: "select", edit: true, },
    { id: 'estado_id', label: <CustomTitleTable title='Estado' />, width: 100, align: 'left', order: true, type: "select", edit: true, },
    { id: 'actions', label: <CustomTitleTable title='actions' />, width: 20, align: 'center', },
];
const columnsMap = {
    estado_id: 'desEstado',
    perfil_id: 'desPerfil',
};

const BodyUser = (props) => {
    const { getInfo, search, states, profiles, data, setData, handleOpenModal, id } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    let resultSearch = filterData(data, search, fieldSearch);

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(resultSearch, sortConfigTable);
    // /** fin ordenamiento de columnas */

    const handleOnChange = (index, info, key, value) => {
        let updData = _.cloneDeep(data);
        const idxTmp = updData.findIndex(e => +e[id] === +info[id]);
        const idx = (idxTmp === -1 ? index : idxTmp);
        updData[idx][key] = value;
        return setData(updData);
    }

    const save = async ({ info }) => {
        const resultSwal = await Swal.fire({
            title: `${+info.estado_id === 2 ? titlesAlerts.confirm_user_inactive : titlesAlerts.confirm_user}`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#229954", // "#3085d6",
            confirmButtonText: "Si!",
            cancelButtonColor: "#d33",
            reverseButtons: true,
        });

        if (resultSwal.isConfirmed) {
            try {
                handleOpenLoader(true);
                handleTitleLoader(titlesSpinner.user_save);
                const result = await UserService.saveOrUpdate(info);
                if (result.status === CODES.SUCCESS_200) {
                    getInfo();
                    simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                } else {
                    simpleAlerts({ message: (result?.response?.data?.message || ""), type: "error" })
                }
                return []
            } catch (error) {
                console.log(`Error en User saveOrUpdate: ${error}`);
            } finally {
                handleOpenLoader(false);
                handleTitleLoader(null);
            }
        }
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            const colorCol =
                                +row.estado_id !== 1 ?
                                    'col-disabled'
                                        : '';
                            return (
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${colorCol} ${col.align}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : +row.edit === 1 ?
                                                        (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Guardar usuario"
                                                                    onClick={() => save({ info: row })}
                                                                >
                                                                    <SaveIcon className="icon-button-table" color={'success'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    size="small"
                                                                    title="Cancelar edición de usuario"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 0)}
                                                                >
                                                                    <CancelIcon className="icon-button-table" color={'warning'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : col.edit ?
                                                                (col.type === 'select' ?
                                                                    (col.id === 'estado_id' ?
                                                                        <CustomSelect
                                                                            placeholder={'Estado'}
                                                                            options={states}
                                                                            value={states.find(e => +e.value === +row.estado_id)?.value}
                                                                            onChange={(value, event) => handleOnChange(idxRow, row, 'estado_id', +value)}
                                                                        />
                                                                        : col.id === 'perfil_id' ?
                                                                            <CustomSelect
                                                                                placeholder={'Perfil'}
                                                                                options={profiles}
                                                                                value={profiles.find(e => +e.value === +row.perfil_id)?.value}
                                                                                onChange={(value, event) => handleOnChange(idxRow, row, 'perfil_id', +value)}
                                                                            />
                                                                            : null
                                                                    )
                                                                    : value
                                                                )
                                                                : value)
                                                        : (col.id === "actions" ?
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Ver Plantas"
                                                                    onClick={() => handleOpenModal({ info: row })}
                                                                >
                                                                    <ApartmentIcon className="icon-button-table" color={'warning'} />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="upload"
                                                                    size="small"
                                                                    title="Editar usuario"
                                                                    onClick={() => handleOnChange(idxRow, row, 'edit', 1)}
                                                                >
                                                                    <EditIcon className="icon-button-table" color={'primary'} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                            : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodyUser;