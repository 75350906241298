import React, { useEffect, } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import CustomInputSingleLine from '../../../components/General/CustomInputSingleLine';
import CustomSelectSingleLine from '../../../components/General/CustomSelectSingleLine';
import _ from 'lodash';

const CINCO_S = ({ handleCancel, handleOpenAddWorker, dataAreas, valuePlant, valueArea, setValueArea, dataSubAreas,
    informationInspection, setInformationInspection, dataCompany, setDataCompany, valueCompany, setValueCompany ,perfil_id}) => {


    const options = informationInspection?.tipo?.opciones;
    const answers = informationInspection?.respuestas;
    const modules = informationInspection?.tipo?.tipo_modulos;

    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            evaluador: null,
            evaluador_id: null,
            evaluado: null,
            evaluado_id: null,
        }));
    }, [valuePlant]);

    useEffect(() => {
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            areaSubArea_id: null,
            evaluador: null,
            evaluador_id: null,
            evaluado: null,
            evaluado_id: null,
        }));
    }, [valueArea]);

    const onChangeRespuesta = ({ info, value, key, }) => {
        let updData = _.cloneDeep(informationInspection)
        const respuestaIndex = updData.respuestas.findIndex(respuesta => +respuesta.pregunta_id === +info.id_pregunta);
        const keyAnswer = info.tipo_respuesta.desc2;
        const opciones_id = info.tipo_respuesta.desTipoRespuesta === "opt" ? info?.opciones_id : null;
        const id_pregunta = info.id_pregunta;
        const tipoRespuesta_id = info.tipoRespuesta_id;

        let respueta = {
            pregunta_id: null,
            tipoRespuesta_id: null,
            opciones_id: null,
            opcion_id: null,
            puntaje: null,
            descipcion: null,
            anomalias: null,
            noConformidades: null,
            observaciones: null,
        };
        respueta["pregunta_id"] = id_pregunta
        respueta["tipoRespuesta_id"] = tipoRespuesta_id
        respueta["opciones_id"] = opciones_id
        if (info.tipo_respuesta.desTipoRespuesta === 'opt') {
            respueta["puntaje"] = info.puntaje
        }

        if (respuestaIndex !== -1) {
            updData.respuestas[respuestaIndex][key === 'answer' ? keyAnswer : key] = value;
        } else {
            if (key === 'answer') {
                respueta[keyAnswer] = value
                updData.respuestas.push(respueta);
            } else {
                respueta[key] = value;
                updData.respuestas.push(respueta);
            }
        }
        setInformationInspection(updData);
        if (key === 'answer') {
            onChangePuntajes({ puntaje: info.puntaje, id_pregunta: id_pregunta, datos: updData })
        }
    }

    const onChangePuntajes = ({ datos }) => {
        const respuestas = datos.respuestas;
        const tipo_modulos = datos.tipo.tipo_modulos;
        const totalModule = datos.tipo.tipo_modulos.length;

        let cumulativeScore = 0;
        const updatedTipoModulos = tipo_modulos.map(modulo => {
            const totalPreguntasDelModulo = modulo?.preguntas?.length;
            const sumaAcum = modulo.preguntas.reduce((acc, pregunta) => {
                const respuesta = respuestas.find(e => +e.pregunta_id === +pregunta.id_pregunta);
                if (respuesta) {
                    acc += +respuesta?.opcion_id;
                }

                return acc;
            }, 0);

            const puntajeModulo = sumaAcum / totalPreguntasDelModulo || 0;
            cumulativeScore += puntajeModulo;
            return {
                ...modulo,
                puntajeModulo: puntajeModulo?.toFixed(2)
            };
        });

        const calculatedScore = (cumulativeScore / totalModule || 0)?.toFixed(2);
        setInformationInspection((prevInformation) => ({
            ...prevInformation,
            puntaje: calculatedScore,
            porcentaje: (calculatedScore * 0.2)?.toFixed(2),
            tipo: {
                ...prevInformation.tipo,
                tipo_modulos: updatedTipoModulos,
            }
        }));
    };

    return (
        <section className='form-register inspection'>
            <div className='row'>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Empresa'}
                        placeholder={'Seleccionar Empresa'}
                        options={dataCompany}
                        value={dataCompany?.find(option => option.id_empresa === valueCompany)}
                        onChange={(value, event) => {
                            const selectedOption = dataCompany?.find(option => option.value === value);
                            setValueCompany(selectedOption?.id_empresa);
                            setInformationInspection({
                                ...informationInspection,
                                empresa_id: selectedOption?.id_empresa,
                                desEmpresa: selectedOption?.desEmpresa,
                            });
                        }
                        }
                        readonly={+perfil_id !== 1 && +perfil_id !== 3}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Area'}
                        placeholder={'Selecionar Area'}
                        options={dataAreas}
                        value={valueArea || 0}
                        onChange={(value, event) => setValueArea(value)}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomSelectSingleLine
                        title={'Sub-Area'}
                        placeholder={'Selecionar SubArea'}
                        options={dataSubAreas}
                        required={true}
                        value={dataSubAreas?.find(option => option.id_areaSubArea === informationInspection?.areaSubArea_id)}
                        onChange={(value, event) => {
                            const selectedOption = dataSubAreas?.find(option => option.value === value);
                            setInformationInspection({
                                ...informationInspection,
                                areaSubArea_id: selectedOption.id_areaSubArea
                            });
                        }}
                    />
                </div>
                <div className='col col-sm-12 col-md-6 col-lg-4'>
                    <CustomInputSingleLine
                        title={"Fecha Inspección"}
                        type={"date"}
                        value={informationInspection?.fecha}
                        name={"fecha"}
                        style={{ width: "80%" }}
                        onChange={(e) => setInformationInspection({ ...informationInspection, fecha: e.target.value })}
                        required={true}
                    />
                </div>
            </div>
            <div className='row justify-content-center'>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Auditor"}
                                name={"auditor"}
                                required={true}
                                value={informationInspection?.evaluador?.trabajador || ""}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "evaluador_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
                <div className='col col-12 col-md-6 col-lg-4'>
                    <div className="header-and-btn-end">
                        <div className="text-header">
                            <CustomInputSingleLine
                                title={"Auditado"}
                                name={"auditado"}
                                required={true}
                                value={informationInspection?.evaluado?.trabajador || ""}
                                readOnly={false}
                            />
                        </div>
                        <button type='button'
                            className="btn-outline-custom primary btn-header-end"
                            onClick={() => handleOpenAddWorker({ key: "evaluado_id" })}
                        >
                            <SearchIcon style={{ fontSize: "15px" }} />
                        </button>
                    </div>
                </div>
            </div>
            {/* preguntas */}
            <div className="table-container">
                <table className="table table-bordered table-sm custom-table">
                    {modules?.map((tipo_modulo) => {
                        const module = tipo_modulo?.modulo;
                        const questions = tipo_modulo?.preguntas;
                        const lengthQuestion = (tipo_modulo?.preguntas?.length ?? 0) + 1;
                        return (
                            <React.Fragment key={`module-${module?.id_modulo}`}>
                                <thead className="border-white">
                                    <tr>
                                        <th className="text-center title-table-inspection" style={{ width: "60px" }} rowSpan={2}>SENSO</th>
                                        <th className="text-center title-table-inspection d-none d-md-table-cell" style={{ width: "120px" }} rowSpan={2}>ÍTEM</th>
                                        <th className="text-center title-table-inspection d-md-none" style={{ width: "20px", fontSize: "0.8rem" }} rowSpan={2}>ÍTEM</th>
                                        <th className="text-center title-table-inspection col-2" style={{ width: "350px" }} rowSpan={2}>Descripción</th>
                                        <th className="text-center title-table-inspection" colSpan={5}>NOTA</th>
                                        <th className="text-center title-table-inspection" rowSpan={2}>OBSERVACIONES</th>
                                    </tr>
                                    <tr>
                                        {options?.map((row) => (
                                            <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>{row?.desc1}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key={`module-${module?.id_modulo}`}>
                                        <td className="text-center title-table-inspection" style={{ width: "40px" }} rowSpan={lengthQuestion}>
                                            <label style={{ width: "50px", transform: 'rotate(-90deg)' }}>{module?.desModulo}</label>
                                        </td>
                                    </tr>
                                    {questions?.map((question) => {
                                        const pregunta_opcion = question.pregunta_opcion;
                                        const answer = answers?.find(e => +e.pregunta_id === +question.id_pregunta);
                                        return (
                                            <tr key={`question-${question?.id_pregunta}`}>
                                                <td className="text-center">{question.desPregunta}</td>
                                                <td className="text-center">{question.detallePregunta}</td>
                                                {options.map((opt) => {
                                                    const item = pregunta_opcion?.find(e => +e.opcion_id === +opt.value);
                                                    return (
                                                        <td
                                                            key={opt.value}
                                                            className={`text-center ${+answer?.opcion_id === opt.value ? 'selected' : ''}`}
                                                            onClick={() => onChangeRespuesta({ info: question, value: opt.value, key: "answer" })}
                                                            style={{ cursor: 'pointer', backgroundColor: +answer?.opcion_id === +opt.value ? 'lightgreen' : 'inherit' }}
                                                        >
                                                            {item?.desripcion}
                                                        </td>
                                                    );
                                                })}
                                                <td className="text-center">
                                                    <input
                                                        className="form-control custom-input"
                                                        placeholder="Comentarios"
                                                        onChange={(e) => onChangeRespuesta({ info: question, value: e.target.value, key: "observaciones" })}
                                                        value={answer?.observaciones || ""}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </React.Fragment>
                        );
                    })}
                </table>
            </div>

            <div className='d-flex justify-content-center gap-2 mb-3'>
                <button className='btn btn-lg btn-outline-danger' type="button" onClick={handleCancel}>
                    Cancelar
                </button>
                <button className='btn btn-lg btn-outline-success' type="submit">
                    Guardar
                </button>
            </div>
            {/* resumen */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                {modules?.map((tipo_modulo) => {
                    const module = tipo_modulo?.modulo;
                    return (
                        <span
                            style={{
                                fontSize: "13px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                        >
                            <label>
                                {`Promedio Nota Sentido de ${module.desModulo}:`}
                            </label>
                            <label style={{ fontWeight: "bold", }}>
                                {tipo_modulo.puntajeModulo}
                            </label>
                        </span>
                    )
                })}
                <span
                    style={{
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "solid 1px black"
                    }}
                >
                    <label>Nota 5S:</label>
                    <label style={{ fontWeight: "bold", }}>
                        {informationInspection?.puntaje}
                    </label>
                </span>
                <span
                    style={{
                        fontSize: "13px",
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <label>% Cumplimiento:</label>
                    <label style={{ fontWeight: "bold", }}>
                        {`${informationInspection?.porcentaje ? (informationInspection?.porcentaje * 100) : ''}%`}
                    </label>
                </span>
            </div>
            {/* Nueva sección similar a la última */}
            <div className="mb-5 col-12 col-md-4"
                style={{
                    border: "solid 1px black",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                <table className="table table-bordered table-sm custom-table">
                    <thead>
                        <tr>
                            <th className="text-center" colSpan={5} style={{ backgroundColor: '#f0f0f0' }}>
                                Leyenda de Notas
                            </th>
                        </tr>
                        <tr>
                            {options?.map((row) => (
                                <th key={row.label} className="text-center col-1" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.desc3}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {options?.map((row) => (
                                <td key={row.label} className="text-center" style={{ width: "30px", backgroundColor: row.desc2 }}>
                                    {row?.label}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
        </section >
    )
}

export default CINCO_S;