import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../../../config/redux/actions';
import { ButtonGroup, IconButton } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TypeBehaviorService from '../../../../../services/typeBehavior';
import CustomCheck from '../../../General/CustomCheck';
import { simpleAlerts } from '../../../../../utils/alerts';
import { titlesAlerts } from '../../../../../message/titlesAlerts';
import { titlesSpinner } from '../../../../../message/titlesSpinner';
import { sortDataList, getValueFromPath } from "../../../../../utils/function";
import { CODES } from '../../../../../utils/codesHTTP';
import Swal from 'sweetalert2';
import _ from 'lodash';

const columns = [
    { id: '#', label: '#', width: 20, align: 'center', },
    { id: 'desTipoEvento', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Tipo Evento</>, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'estado', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "18px", marginRight: "10px", }} />Estado</>, width: 100, align: 'left', order: true, type: "select", edit: true, },
    { id: 'sel', label: <ConstructionIcon key="icon" style={{ fontSize: "18px", }} />, width: 20, align: 'center', },
];

const columnsMap = {
    estado: 'estado.desEstado',
};

const BodyTypeEventAssignment = (props) => {
    const { getInfo, data, setData, dataExists, dataSelected, setDataSelected, id } = props;

    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

    const saveOrUpdateTypeEventByArea = async (idx, info, estado_id) => {
        if (!info[id] && estado_id === 2) {
            let updData = _.cloneDeep(data);
            updData = updData?.filter((e, index) => index !== idx);
            setData(updData);
        } else {
            const resultSwal = await Swal.fire({
                title: `${+estado_id === 2 ? titlesAlerts.confirm_behavior_inactive : titlesAlerts.confirm_behavior} (${info?.desTipoEvento})`,
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#229954", // "#3085d6",
                confirmButtonText: "Si!",
                cancelButtonColor: "#d33",
                reverseButtons: true,
            });

            if (resultSwal.isConfirmed) {
                try {
                    info = { ...info, estado_id };
                    handleOpenLoader(true);
                    handleTitleLoader(titlesSpinner.behavior_save);
                    const result = await TypeBehaviorService.saveOrUpdate(info);
                    if (result.status === CODES.SUCCESS_200) {
                        getInfo();
                        simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
                    } else {
                        simpleAlerts({ message: (result?.response?.data?.message || ""), type: "error" })
                    }
                    return []
                } catch (error) {
                    console.log(`Error en Behavior saveOrUpdate: ${error}`);
                } finally {
                    handleOpenLoader(false);
                    handleTitleLoader(null);
                }
            }
        }
    }

    const handleChangeCheck = (check, info) => {
        let updData = _.cloneDeep(dataSelected);
        if (!updData) {
            updData = ([]);
        }

        const idxExists = updData?.findIndex(e => +e[id] === +info[id]);
        if (check === false) {
            updData = updData?.filter((e, i) => i !== idxExists)
        } else {
            if (idxExists === -1) {
                updData.push(info);
            }
        }
        setDataSelected(updData);
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            const colorCol =
                                +row.estado_id !== 1 ?
                                    'col-disabled'
                                    : dataExists?.find(e => +e[id] === +row[id]) ?
                                        'col-assigned'
                                        : '';
                            return (
                                +row.estado_id === 1 &&
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align} ${colorCol}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : (col.id === "actions" ?
                                                        <ButtonGroup>

                                                            <IconButton
                                                                aria-label="delete"
                                                                size="small"
                                                                title="Eliminar tipo evento"
                                                                onClick={() => saveOrUpdateTypeEventByArea(idxRow, row, 2)}
                                                            >
                                                                <DeleteIcon className="icon-button-table" color={'error'} />
                                                            </IconButton>
                                                        </ButtonGroup>
                                                        : col.id === "sel" ?
                                                            <CustomCheck
                                                                id={`chk-body-${id}-${row[id]}`}
                                                                handleChangeCheck={handleChangeCheck}
                                                                info={row}
                                                                checked={(dataSelected?.find(e => +e[id] === +row[id])) ? true : false}
                                                            />
                                                            : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodyTypeEventAssignment;