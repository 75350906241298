import axios from "axios";
import headerRequest from "../config/headers";

const API_URL = process.env.REACT_APP_API_URL_SST;
const prefix = 'inspection';
const url = window.location.pathname;
const commonParams = { url: url };

class InspectionService {
    async getInformationType(queryParameters) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/information/type`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async getTypeInspection(queryParameters = {}) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/type`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async getInspection(queryParameters) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async getListInspectionsByDate(queryParameters = {}) {
        const params = { ...commonParams, ...queryParameters };
        const ruta = `${API_URL}/${prefix}/list/dates`;
        const headers = await headerRequest();
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async createInspection(body) {
        const ruta = `${API_URL}/${prefix}`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.post(ruta, body, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }

    async getListEmpresasByUser() {
        const ruta = `${API_URL}/${prefix}/list/empresas`;
        const headers = await headerRequest();
        const params = { ...commonParams };
        return axios.get(ruta, {
            headers: headers,
            params: params,
        }).catch((error) => {
            return error;
        });
    }
}

export default new InspectionService();