import React, { useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import SearchIcon from '@mui/icons-material/Search';
import InspectionService from '../../../services/inspection';
import ReportService from '../../../services/report';
import { ButtonGroup, IconButton } from '@mui/material';
import { format, addDay, diffDays } from "@formkit/tempo"
import DynamicTitle from '../../../utils/DynamicTitle';
import CustomInput from '../../components/General/CustomInput';
import CustomTitleTable from '../../components/General/CustomTitleTable';
import sheetImg from '../../../assets/icons/pdf.png'
import { simpleAlerts } from '../../../utils/alerts';
import { validationMessages } from '../../../message/validations';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { errorsMessages } from '../../../message/errors';
import { CODES } from '../../../utils/codesHTTP';
import GeneralService from '../../../services/general';

const columns = [
    { id: 'fecha', label: <CustomTitleTable title='FECHA' sort={false} />, },
    { id: 'hora', label: <CustomTitleTable title='HORA' sort={false} />, },
    // { id: 'cantSugerencias', label: <CustomTitleTable title='SUGERENCAS' sort={false} />, },
    // { id: 'cantPlanesAccion', label: <CustomTitleTable title='PLANES ACCIÓN' sort={false} />, },
    // { id: 'cantRespuestas', label: <CustomTitleTable title='RESPUETAS' sort={false} />, },
    { id: 'evaluado', label: <CustomTitleTable title='EVALUADO' sort={false} />, },
    { id: 'supervisor', label: <CustomTitleTable title='SUPERVISOR' sort={false} />, },
    { id: 'empresa', label: <CustomTitleTable title='EMPRESA' sort={false} />, },
    { id: 'planta', label: <CustomTitleTable title='PLANTA' sort={false} />, },
    { id: 'area', label: <CustomTitleTable title='ÁREA' sort={false} />, },
    { id: 'subArea', label: <CustomTitleTable title='SUB-ÁREA' sort={false} />, },
    { id: 'usuario', label: <CustomTitleTable title='USUARIO' sort={false} />, },
    { id: 'fechaMod', label: <CustomTitleTable title='REGISTRO' sort={false} />, },
    { id: 'actions', label: <CustomTitleTable title='actions' sort={false} />, },
];

const ViewInspection = () => {
    const dateLanguage = 'es';
    const day_limit = 15;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    const [types, setTypes] = useState([]);
    const [inspectionList, setInspectionList] = useState([]);
    const [fechaDesde, setFechaDesde] = useState(format(addDay(new Date(), -day_limit), "YYYY-MM-DD", dateLanguage));
    const [fechaHasta, setFechaHasta] = useState(format(new Date(), "YYYY-MM-DD", dateLanguage));

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        handleOpenLoader(true);
        handleTitleLoader("Buscando Inspecciones");
        Promise.all([
            getTypeInspection(),
            getListInspectionsByDate()
        ]).then(() => { handleOpenLoader(false); handleTitleLoader(null); });
    }

    const getTypeInspection = async () => {
        try {
            setTypes([]);
            const result = await InspectionService.getTypeInspection();
            setTypes(result.data);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    const getListInspectionsByDate = async () => {
        try {
            if (diffDays(fechaHasta, fechaDesde) > day_limit) {
                simpleAlerts({ message: validationMessages.limit_days_inspection })
                return;
            }
            handleOpenLoader(true);
            handleTitleLoader("Buscando Inspecciones");

            setInspectionList([]);
            const params = { fechaDesde, fechaHasta }
            const result = await InspectionService.getListInspectionsByDate(params);
            setInspectionList(result.data);

            handleOpenLoader(false);
            handleTitleLoader(null);
            return [];
        } catch (error) {
            console.log(`Error en showShiftByPlant: ${error}`);
        }
    }

    const showTopCompanyByPlant = async (planta_id) => {
        try {
            const result = await GeneralService.showTopCompanyByPlant(planta_id);
            return result.data || { rucEmpresa: "20454553277" };
        } catch (error) {
            console.log(`Error en showTopCompanyByPlant: ${error}`);
        }
    };

    const generateReport = async ({ id_inspeccion, id_tipo }) => {
        try {
            handleTitleLoader(titlesSpinner.report_inspection_generate);
            handleOpenLoader(true);
            const informationInspection = await getInspection(id_inspeccion, id_tipo)
            if (informationInspection) {
                const rucEmpresa = await showTopCompanyByPlant(informationInspection?.area_sub_area?.planta_area?.planta?.id_planta);
               
                switch (id_tipo) {
                    case 4:
                    case 3:
                    case 1:
                        await generateReportDTO(informationInspection, rucEmpresa?.rucEmpresa);
                        break;
                    case 2:
                        await generateReportSST(informationInspection, rucEmpresa?.rucEmpresa);
                        break;
                    case 5:
                        await generateReport5S(informationInspection, rucEmpresa?.rucEmpresa);
                        break;
                    default:
                        simpleAlerts({ message: validationMessages.not_found_inspection });
                }
            } else {
                simpleAlerts({ message: errorsMessages.report_information })
                handleTitleLoader(null);
                handleOpenLoader(false);
            }
            return [];
        } catch (error) {
            console.log(`Error en generateReport: ${error}`);
        }
    }

    const getInspection = async (idInspection, idType) => {
        try {
            const queryParameters = { id_inspeccion: idInspection, tipo_id: idType };
            const result = await InspectionService.getInspection(queryParameters);
            return result.data;
        } catch (error) {
            console.log(`Error en Inspection: ${error}`);
        }
    }

    const generateReportDTO = async (informationInspection, rucEmpresa) => {
        try {
            handleOpenLoader(true);
            const result = await ReportService.generateReportDTO(informationInspection, rucEmpresa);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "Inspección_" + informationInspection?.tipo?.abreviatura + "_" + informationInspection?.id_inspeccion + "_" + informationInspection?.planta + "_" + informationInspection?.fecha + '.pdf';
                link.click();
                URL.revokeObjectURL(url);
            }
            handleOpenLoader(false);
            return [];
        } catch (error) {
            console.log(`Error en generateReportDTO: ${error}`);
        }
    }

    const generateReportSST = async (informationInspection, rucEmpresa) => {
        try {
            handleOpenLoader(true);
            const result = await ReportService.generateReportSST(informationInspection, rucEmpresa);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "Inspección_" + informationInspection?.tipo?.abreviatura + "_" + informationInspection?.id_inspeccion + "_" + informationInspection?.planta + "_" + informationInspection?.fecha + '.pdf';
                link.click();
                URL.revokeObjectURL(url);
            }
            handleOpenLoader(false);
            return [];
        } catch (error) {
            console.log(`Error en generateReportSST: ${error}`);
        }
    }

    const generateReport5S = async (informationInspection, rucEmpresa) => {
        try {
            handleOpenLoader(true);
            const result = await ReportService.generateReport5S(informationInspection, rucEmpresa);
            if (result.status === CODES.SUCCESS_200) {
                const blob = new Blob([result.data], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = "Inspección_" + informationInspection?.tipo?.abreviatura + "_" + informationInspection?.id_inspeccion + "_" + informationInspection?.planta + "_" + informationInspection?.fecha + '.pdf';
                link.click();
                URL.revokeObjectURL(url);
            }
            handleOpenLoader(false);
            return [];
        } catch (error) {
            console.log(`Error en generateReport5S: ${error}`);
        }
    }

    const searchInspectionDetail = async ({ data }) => {
        const { id_inspeccion, id_tipo } = data;
        // Guardar los datos grandes en el almacenamiento local
        localStorage.setItem('inspectionData', JSON.stringify(data));
        // Abrir una nueva ventana con la URL deseada
        const url = `/inspection-detail/${id_tipo}/${id_inspeccion}`;
        window.open(url, '_blank');
    };

    return (
        <form className="px-3">
            <DynamicTitle title="Ver Inspecciones" />
            <h1 className='text-center title-page'>Listado de inspecciónes</h1>
            <section className='form-register'>
                <div className='row justify-content-center gap-2 align-items-end'>
                    <div className='col-md-4 col-sm-8'>
                        <CustomInput
                            title={"Desde"}
                            type={"date"}
                            value={fechaDesde}
                            name={"fecha"}
                            onChange={(e) => setFechaDesde(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-md-4 col-sm-8'>
                        <CustomInput
                            title={"Hasta"}
                            type={"date"}
                            value={fechaHasta}
                            name={"fecha"}
                            onChange={(e) => setFechaHasta(e.target.value)}
                            required={true}
                        />
                    </div>
                    <div className='col-1'>
                        <button
                            className='btn btn-sm btn-outline-primary ms-2'
                            type='button'
                            onClick={getListInspectionsByDate}
                            title='Buscar Inspecciones'
                        >
                            <SearchIcon fontSize='small' />
                        </button>
                    </div>
                </div>
                <div className="accordion container" id="types-inspection">
                    {types?.map((type) => {
                        const inspections = inspectionList?.filter(e => +e.id_tipo === +type.id_tipo);
                        const numberInspections = inspections?.length || 0;
                        return (
                            <div className="accordion-item" key={`title-type-inspection-${type.id_tipo}`}>
                                <h2 className="accordion-header" id={`header-inspection-${type.id_tipo}`}>
                                    <button
                                        className="accordion-button btn-sm collapsed"
                                        style={{
                                            fontSize: '14px',
                                            padding: '8px',
                                        }}
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target={`#collapse-inspection-${type?.id_tipo}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse-inspection-${type?.id_tipo}`}
                                    >
                                        <span>{type.desTipo}</span>
                                        &nbsp;&nbsp;&nbsp;
                                        <span style={{ backgroundColor: 'black', color: 'white', borderRadius: '10px', padding: '2px 8px', }}>
                                            {numberInspections}
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id={`collapse-inspection-${type?.id_tipo}`}
                                    className="accordion-collapse collapse"
                                    aria-labelledby={`header-inspection-${type?.id_tipo}`}
                                    data-bs-parent="#types-inspection"
                                >
                                    <div className="accordion-body">
                                        <div className="table-responsive-sm">
                                            <table className="table table-bordered table-sm">
                                                <thead className='sticky-top table-dark header-table'>
                                                    <tr>
                                                        {columns?.map((col) => {
                                                            return (
                                                                <th
                                                                    key={`header-${col.id}`}
                                                                    className={`${col.align} ${col.order && 'sortable'} text-center th-table-head`}
                                                                >
                                                                    {col.label}
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {inspections?.map((row, idxRow) => {
                                                        return (
                                                            <tr key={"row" + idxRow}>
                                                                {columns.map((col, idxCol) => {
                                                                    const value = row[col.id];
                                                                    return (
                                                                        <td key={'col' + idxCol}>
                                                                            {col.id === "actions" ?
                                                                                <ButtonGroup>
                                                                                    <IconButton
                                                                                        aria-label="upload"
                                                                                        size="small"
                                                                                        title="Imprimir-inspección"
                                                                                        onClick={() => generateReport({ id_inspeccion: row.id_inspeccion, id_tipo: +row.id_tipo })}
                                                                                    >
                                                                                        <img src={sheetImg} width={20} />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        aria-label="upload"
                                                                                        size="small"
                                                                                        title="Buscar detalle"
                                                                                        onClick={() => searchInspectionDetail({ data: row })}
                                                                                    >
                                                                                        <PlagiarismIcon fontSize='inherit' color={'primary'} />
                                                                                    </IconButton>
                                                                                </ButtonGroup>
                                                                                : value
                                                                            }
                                                                        </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
        </form>
    )
}

export default ViewInspection