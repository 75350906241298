import React from 'react'
import QuestionRow from './QuestionRow';

export default function ModuleRow ({ module, puntaje, questions, informationInspection, onChange, idType }) {
    return (
        <>
            <tr key={`module-${module.id_modulo}`}>
                { (+idType === 4 || +idType === 3 || +idType === 1) ?               
                    <td colSpan={999} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`Asunto: ${module.desModulo}`}</td>
                : (+idType === 2) ?
                    <>
                        <td className="text-center" colSpan={2} style={{ background: "#BFBFBF", fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{module.desModulo}</td>
                        <td style={{ fontSize: "13px", verticalAlign: 'middle', fontWeight: "bold" }}>{`% Cumplimiento: ${puntaje || ''} %`}</td>                    
                    </>
                : <h1 className='text-center title-page'>Wao! el area de desarrollo estara muy interesado en saber como llegaste aqui!</h1> }
            </tr>
            {questions.map((question) => {
                const answer = informationInspection.respuestas.find(e => +e.pregunta_id === +question.id_pregunta);
                return (
                    <QuestionRow
                        key={`question-${question.id_pregunta}`}
                        question={question}
                        answer={answer}
                        onChange={onChange}
                        idType={idType}
                    />
                );
            })}
        </>
    );
};