import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setOpenLoader, setTitleLoader } from '../../../config/redux/actions';
import { ButtonGroup, IconButton } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FilesServive from '../../../services/file';
import UnsafeConditionService from '../../../services/unsafeCondition';
import CustomTitleTable from '../General/CustomTitleTable';
import CustomTextArea from '../General/CustomTextArea';
import { fileTypesUnsafeBehavior } from '../../../utils/allowedFileTypes';
import { sortDataList, getValueFromPath, formatDate, } from "../../../utils/function";
import { CODES } from '../../../utils/codesHTTP';
import { titlesSpinner } from '../../../message/titlesSpinner';
import { simpleAlerts } from '../../../utils/alerts';
import { errorsMessages } from '../../../message/errors';
import { validationMessages } from '../../../message/validations';
import _ from 'lodash';

const columns = [
    { id: '#', label: '#', width: 20, align: 'center', },
    { id: 'fecha', label: <CustomTitleTable title='Fecha' />, width: 150, align: 'left', format: (value) => formatDate(new Date(value), 'date-time', 'short'), },
    { id: 'desPlanta', label: <CustomTitleTable title='Planta' />, width: 100, align: 'left', order: true, },
    { id: 'desArea', label: <CustomTitleTable title='Área' />, width: 100, align: 'left', order: true, },
    { id: 'desLugar', label: <CustomTitleTable title='Lugar' />, width: 100, align: 'left', order: true, },
    { id: 'desTurno', label: <CustomTitleTable title='Turno' />, width: 100, align: 'left', },
    { id: 'desSuceso', label: <CustomTitleTable title='Suceso' />, width: 100, align: 'left', },
    { id: 'usuario', label: <CustomTitleTable title='Usuario' />, width: 50, align: 'left', },
    { id: 'imagenes', label: <CustomTitleTable title='Imagen' />, width: 80, align: 'left', },
    { id: 'desCierre', label: <CustomTitleTable title='Cierre' />, width: 100, align: 'left', type: "text", edit: true, },
    { id: 'imagenes_cierre', label: <CustomTitleTable title='ImagenCierre' />, width: 80, align: 'left', },
    { id: 'actions', label: <CustomTitleTable title='actions' />, width: 20, align: 'center', },
];
const columnsMap = {
    estado: 'estado.desEstado',
};

const BodyUnsafeCondition = (props) => {
    const { data, setData, id, } = props;
    const dispatch = useDispatch();
    const handleOpenLoader = (value) => dispatch(setOpenLoader(value));
    const handleTitleLoader = (value) => dispatch(setTitleLoader(value));

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

    const handleOnChange = (value, key, info) => {
        let updData = _.cloneDeep(data);
        const idxTmp = updData.findIndex(e => +e[id] === +info[id]);
        updData[idxTmp][key] = value;
        return setData(updData);
    }

    const handleFilesChange = (e, { info, field, }) => {
        let updData = _.cloneDeep(data);
        const idxTmp = updData.findIndex(e => +e.id_condicInsegu === +info.id_condicInsegu);
        const files = e.target.files;

        if (files.length > 5) {
            simpleAlerts({ message: errorsMessages.max_files_images });
            return;
        }

        if (!updData[idxTmp][field]) {
            updData[idxTmp][field] = [];
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileExtension = file?.name?.split('.').pop().toLowerCase();

            if (!fileTypesUnsafeBehavior.includes(`.${fileExtension}`)) {
                simpleAlerts({ message: errorsMessages.type_image })
            } else {
                let small;
                if (file) {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        small = { file: file, img: event.target.result, name: file?.name };
                        updData[idxTmp][field].push({ file: file, small: small });
                        setData(updData);
                    };
                    reader.readAsDataURL(file);
                }
            }
        }
    };

    const handleRemoveFile = ({ info, field, idx }) => {
        let updData = _.cloneDeep(data);
        const idxTmp = updData.findIndex(e => +e.id_condicInsegu === +info.id_condicInsegu);
        updData[idxTmp][field] = updData[idxTmp][field]?.filter((e, i) => +i !== idx);
        setData(updData);
    };

    const saveUnsafeConditionClosure = async ({ info }) => {
        if (!info.desCierre || info.desCierre === "") {
            simpleAlerts({ message: validationMessages.desCierre })
            return;
        }

        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.comportamientoInseguro_save);

            const result = await UnsafeConditionService.saveUnsafeConditionClosure(info);
            if (result.status === CODES.CREATE_201) {
                let updData = _.cloneDeep(data);
                const idxTmp = updData.findIndex(e => +e.id_condicInsegu === +info.id_condicInsegu);
                updData = updData?.filter((e, i) => +i !== idxTmp);
                setData(updData);

                simpleAlerts({ message: (result?.data?.message || ""), type: "success" })
            } else {
                console.log(result?.response);
                simpleAlerts({ message: errorsMessages.unsafe_behavior, type: "error" })
            }
            return []
        } catch (error) {
            console.log(`Error en saveUnsafeBehavior: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null);
        }
    }

    const downloadFileS3 = async (ruta) => {
        try {
            handleOpenLoader(true);
            handleTitleLoader(titlesSpinner.file_download)
            const result = await FilesServive.downloadFileS3(ruta);
            if (result.status === CODES.SUCCESS_200) {
                const url = window.URL.createObjectURL(new Blob([result.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', ruta);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            } else {
                simpleAlerts({ message: errorsMessages.file_download, type: 'error' });
            }
        } catch (error) {
            console.log(`Error al descargar el archivo: ${error}`);
        } finally {
            handleOpenLoader(false);
            handleTitleLoader(null)
        }
    }

    return (
        <div className="frame-table">
            <div className="table-responsive body-table">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            return (
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = col.id in columnsMap ? getValueFromPath(row, columnsMap[col.id]) : row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : (col.id === "imagenes") ?
                                                        <div className="d-flex justify-content-center flex-wrap">
                                                            {value?.map((img, idxImg) => {
                                                                return (
                                                                    <GetAppIcon
                                                                        key={`icon-${idxImg}`}
                                                                        style={{
                                                                            fontSize: '18px',
                                                                            margin: '2px'
                                                                        }}
                                                                        onClick={() => downloadFileS3(img.ruta)}
                                                                        className={`btn btn-sm px-0 py-0 btn-outline-${(idxImg % 2 === 0) ? 'secondary' : 'primary'}`}
                                                                    />
                                                                )
                                                            })}
                                                        </div>
                                                        : (col.id === "imagenes_cierre") ?
                                                            <div className='justify-content-center gap-2'>
                                                                <div className='text-center mb-1'>
                                                                    <input
                                                                        id={`fileInput-form-register`}
                                                                        type="file"
                                                                        accept={fileTypesUnsafeBehavior}
                                                                        onChange={(e) => handleFilesChange(e, { info: row, field: 'imagenes_cierre' })}
                                                                        style={{ display: 'none' }}
                                                                        multiple
                                                                    />
                                                                    <button
                                                                        className='btn btn-sm btn-outline-primary py-0 px-1'
                                                                        type='button'
                                                                        onClick={() => document.getElementById(`fileInput-form-register`).click()}
                                                                        title='Buscar imagenes'
                                                                    >
                                                                        <CloudUploadIcon color="inherit" fontSize='inherit' />
                                                                    </button>
                                                                </div>
                                                                {value?.length > 0 &&
                                                                    <div className="d-grid gap-1">
                                                                        {value?.map((image, idxImage) => {
                                                                            return (
                                                                                <div key={`image-${idxImage}`} className="d-flex gap-2 border-end-1">
                                                                                    <button
                                                                                        className='btn btn-sm btn-outline-danger p-0 border-0'
                                                                                        type='button'
                                                                                        onClick={() => handleRemoveFile({ info: row, field: "imagenes_cierre", idx: idxImage },)}
                                                                                        title='Quitar imagen'
                                                                                    >
                                                                                        <CancelIcon fontSize='small' />
                                                                                    </button>
                                                                                    <img
                                                                                        src={image?.small?.img}
                                                                                        alt=""
                                                                                        style={{ width: '30px', cursor: 'pointer' }}
                                                                                        className='ms-3'
                                                                                        //onClick={() => viewFile(image)}
                                                                                    />
                                                                                    <label className='text-truncate'>{image?.small?.name}</label>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                }
                                                            </div>
                                                            : (col.id === "desCierre") ?
                                                                <CustomTextArea
                                                                    placeholder="ingrese una descripción 500 max."
                                                                    propsInput={{ maxLength: 500, }}
                                                                    value={value || ""}
                                                                    onChange={(e) => handleOnChange(e.target.value, col.id, row)}
                                                                    required={true}
                                                                />
                                                                : (col.id === "actions" ?
                                                                    <ButtonGroup>
                                                                        <IconButton
                                                                            aria-label="upload"
                                                                            size="small"
                                                                            title="Guardar usuario"
                                                                            onClick={() => saveUnsafeConditionClosure({ info: row })}
                                                                        >
                                                                            <SaveIcon className="icon-button-table" color={'success'} />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            size="small"
                                                                            title="Cancelar edición de usuario"
                                                                            onClick={() => handleOnChange(idxRow, row, 'edit', 0)}
                                                                        >
                                                                            <CancelIcon className="icon-button-table" color={'warning'} />
                                                                        </IconButton>
                                                                    </ButtonGroup>
                                                                    : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BodyUnsafeCondition;