import React, { useState } from 'react'
import { Box, ButtonGroup, IconButton } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CustomPagination from '../General/CustomPagination';
import { sortDataList, } from '../../../utils/function';
import _ from 'lodash';

const columns = [
    { id: 'numdoc', label: '#', width: 20, align: 'left', },
    { id: 'trabajador', label: <><FormatListNumberedIcon key="icon" style={{ fontSize: "15px", marginRight: "10px", }} />Trabajador</>, width: 150, align: 'left', order: true, type: "text", edit: true, },
    { id: 'actions', label: <ConstructionIcon key="icon" style={{ fontSize: "15px", }} />, width: 20, align: 'center', },
];

const BodyWorkersList = (props) => {
    const { page, setPage, totalPage, data, dataSelected, handleAdd, } = props;

    // /** inicio ordenamiento de columnas */
    const [sortConfigTable, setSortConfigTable] = useState({ key: null, direction: "ascending", });
    const handleSortTable = (key) => {
        key = (key === "estado" ? "estado_id" : key);
        let direction = "ascending";
        if (sortConfigTable && sortConfigTable.key === key && sortConfigTable.direction === "ascending") {
            direction = "descending";
        }
        setSortConfigTable({ key, direction });
    };
    const srtdData = sortDataList(data, sortConfigTable);
    // /** fin ordenamiento de columnas */

    return (
        <div className="frame-table mt-2">
            <div className="table-responsive body-table small">
                <table className="table table-hover table-bordered">
                    <thead className='sticky-top table-dark header-table'>
                        <tr>
                            {columns?.map((col) => {
                                return (
                                    <th
                                        key={`header-${col.id}`}
                                        className={`${col.align} ${col.order && 'sortable'} th-table-head`}
                                        style={{ width: `${col.width}px`, maxWidth: `${col.width}px`, }}
                                        onClick={() => col.order && handleSortTable(col.id)}
                                    >
                                        {col.label}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {srtdData?.map((row, idxRow) => {
                            const validatioPersona = dataSelected?.find(e => +e.id_persona === +row.id_persona);
                            return (
                                <tr key={"row" + idxRow}>
                                    {columns.map((col, idxCol) => {
                                        const value = row[col.id];
                                        return (
                                            <td key={'col' + idxCol} className={`${col.align}`}>
                                                {col.id === "#" ?
                                                    idxRow + 1
                                                    : (col.id === "actions" ?
                                                        (!validatioPersona &&
                                                            <ButtonGroup>
                                                                <IconButton
                                                                    aria-label="selected"
                                                                    size="small"
                                                                    title="Seleccionar trabajador"
                                                                    onClick={() => handleAdd({ idx: idxRow, info: row })}
                                                                >
                                                                    <LibraryAddIcon className="icon-button-table" color={'success'} style={{ fontWeight: "bolder" }} />
                                                                </IconButton>
                                                            </ButtonGroup>
                                                        )
                                                        : value)
                                                }
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {totalPage > 1 &&
                <CustomPagination
                    totalPages={totalPage}
                    page={page}
                    setPage={setPage}
                />
            }
        </div>
    )
}

export default BodyWorkersList;